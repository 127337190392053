body {
  background-color: white;
}

.App {
  
}

.App-header {
  background-color: #f3f3f3;
  height: 150px;
  padding: 20px;
  color: #152935;
}

.App-header_content {
  max-width: 90rem;
  margin: 0 auto;
}

.App-content {
  padding: 20px;
}

.App-content_content {
  max-width: 90rem;
  margin: 0 auto;
}

.App-title {
  padding-top: 0.6em;
  font-size: 1.5em;
}

.App-intro {
  padding-top: 0.4em;
  padding-bottom: 0.4em;
}

.App-subtitle {
  font-size: 0.8em;
  font-weight: lighter;
}

.namespace {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4em;
}

.namespace-title {
  font-size: 1.4em;
  padding-bottom: 0.2em;
}

.namespace-subtitle {
  font-weight: lighter;
  font-size: 1.1em;
}

.empty {
  margin-top: 1em;
}

.namespace-count {
  margin-top: 0.4em;
  margin-bottom: 1.4em;
}

.service {
  min-width: 20em;
  min-height: 6em;
  margin-top: 0.7em;
  margin-bottom: 0.7em;
  margin-right: 1.4em;
}

.service-title {
  font-size: 1.2em;
}

.tags {
  padding-top: 0.4em;
  display: flex;
}
